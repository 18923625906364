// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-success-index-js": () => import("./../src/pages/contact/success/index.js" /* webpackChunkName: "component---src-pages-contact-success-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-cmi-index-js": () => import("./../src/pages/projects/cmi/index.js" /* webpackChunkName: "component---src-pages-projects-cmi-index-js" */),
  "component---src-pages-projects-drone-aerial-surveying-index-js": () => import("./../src/pages/projects/drone-aerial-surveying/index.js" /* webpackChunkName: "component---src-pages-projects-drone-aerial-surveying-index-js" */),
  "component---src-pages-projects-helping-minds-index-js": () => import("./../src/pages/projects/helping-minds/index.js" /* webpackChunkName: "component---src-pages-projects-helping-minds-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-ppl-architects-index-js": () => import("./../src/pages/projects/ppl-architects/index.js" /* webpackChunkName: "component---src-pages-projects-ppl-architects-index-js" */)
}

